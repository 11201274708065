<script>
  import "./main.css";
  import { onMount } from "svelte";
  import SumatraLogo from "./SumatraLogo.svelte";
  // @ts-ignore
  import FS from "https://cdn.skypack.dev/pin/@isomorphic-git/lightning-fs@v4.4.1-81Eg7T54W1Xwdb7bvsuN/mode=imports,min/optimized/@isomorphic-git/lightning-fs.js";
  import "./main.css";
  import { humanizeSize, len, preventDragOnElement } from "./common";

  let files = [];

  const fs = new FS("files").promises;
  console.log(fs);

  // this is where we store files in FS virtual directory
  const filesDir = "/";

  async function saveFiles(files) {
    console.log("saveFiles:", files);
    const firstFile = files[0];
    //console.log("firstFile:", firstFile);

    // TODO: save all files locally
    //fileToShow.set(firstFile);
    const fileName = firstFile.name;
    const buffer = await firstFile.arrayBuffer();
    const view = new Uint8Array(buffer);

    await fs.writeFile(filesDir + fileName, view);
    console.log("Wrote file to storage:", fileName);
    // const uri = "/viewlocal?file=" + encodeURIComponent(fileName);
    //location.pathname = uri;
    //router.run(uri);
    updateFileList();
  }

  /**
   * @param {DragEvent} e
   */
  async function handleDrop(e) {
    console.log("handleDrop", e);
    let dt = e.dataTransfer;
    await saveFiles(dt.files);
  }

  async function handleFiles(e) {
    console.log("handleFiles", e);
    const files = e.target.files;
    await saveFiles(files);
  }

  function toggleHili(e) {
    const clsList = e.target.classList;
    const cls = "bg-gray-100";
    ["dragenter", "dragover"].includes(e.type)
      ? clsList.add(cls)
      : clsList.remove(cls);
  }

  async function updateFileList() {
    console.log("updateFileList");
    const dir = "/";
    const fileNames = await fs.readdir(dir);
    let newFiles = [];
    for (let fileName of fileNames) {
      const st = await fs.stat(dir + fileName);
      // TODO: handing of directories must be different
      if (st.isFile()) {
        st.name = fileName;
        newFiles.push(st);
      }
    }
    function sortByNames(o1, o2) {
      if (o1.name > o2.name) {
        return 1;
      }
      if (o1.name == o2.name) {
        return 0;
      }
      return -1;
    }
    newFiles.sort(sortByNames);
    files = newFiles;
    console.log("set files to:", files);
  }

  onMount(async () => {
    console.log("Home onMount()");

    preventDragOnElement(document);
    document.addEventListener("drop", handleDrop, false);

    let dropArea = document.getElementById("drop-area");
    preventDragOnElement(dropArea);
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, toggleHili, false);
    });
    dropArea.addEventListener("drop", handleDrop, false);

    await updateFileList();
  });
</script>

<SumatraLogo />

<div class="fixed right-4 top-2">
  <a class="text-blue-700 underline" href="/tools">tools</a>
  <a
  class="ml-2 text-blue-700 underline"
  href="https://blog.kowalczyk.info/contactme.html"
  target="_blank">contact</a
>

</div>


<div class="px-4 w-full h-2/6">
  <div
    id="drop-area"
    on:drop={handleDrop}
    class="flex rounded-md border-2 border-dashed border-gray-400 items-center justify-center h-full my-2 text-xl bg-gray-50"
  >
    <form class="mb-4 flex flex-col items-center justify-center h-full">
      <p>drop PDF file to view</p>
      <input type="file" class="hidden" id="fileElem" on:change={handleFiles} />
      <label
        class="rounded bg-gray-300 mt-2 px-4 py-2 hover:bg-gray-200"
        for="fileElem">Select file to view</label
      >
    </form>
  </div>
</div>

<div class="table w-11/12 mt-4 ml-4">
  {#if len(files) > 0}
    <div class="text-2xl mb-2">Local files:</div>
    {#each files as fo}
      <div class="table-row">
        <a class="table-cell w-4/12 hover:bg-gray-200" href="/viewpdfjs?file={fo.name}"
          >{fo.name}</a
        >
        <div class="table-cell ml-2">{humanizeSize(fo.size)}</div>
      </div>
    {/each}
  {/if}
</div>
