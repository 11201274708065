<style>
  .logo {
    font-family: "Arial Black", Arial;
  }
</style>

<div class="flex pb-4 ml-4">
  <div class="logo flex font-bold text-3xl mr-8 mt-2">
      <div style="color: rgb(196, 64, 50)">S</div>
      <div style="color: RGB(227, 107, 35)">u</div>
      <div style="color: RGB(93, 160, 40)">m</div>
      <div style="color: RGB(69, 132, 190)">a</div>
      <div style="color: RGB(112, 115, 207)">t</div>
      <div style="color: RGB(112, 115, 207)">r</div>
      <div style="color: RGB(69, 132, 190)">a</div>
      <div style="color:RGB(93, 160, 40) ">P</div>
      <div style="color: RGB(227, 107, 35)">D</div>
      <div style="color: rgb(196, 64, 50)">F</div>
      <div>&nbsp;</div>
      <div style="color: rgb(196, 64, 50)">O</div>
      <div style="color: RGB(227, 107, 35)">n</div>
      <div style="color: RGB(93, 160, 40)">l</div>
      <div style="color: RGB(69, 132, 190)">i</div>
      <div style="color: RGB(112, 115, 207)">n</div>
      <div style="color: RGB(93, 160, 40)">e</div>
      <div class="text-xs text-gray-500">v0.1</div>
  </div>
</div>
